import React, { useState, useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import { Formik } from 'formik';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { css } from 'styled-components';
import 'date-fns';
import ReCAPTCHA from 'react-google-recaptcha';
import { useMediaQuery } from 'react-responsive';
import MailingListPopup from '../components/JoinMailingList/MailingListPopup';

import { Default, Mobile } from '~/components/.base/responsive';

import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import { appendFormData, getImage, stateLists } from '~/helpers';
import { breakpoint, size } from '../constants/Breakpoint';
import { LargeStencil } from '../components/.base/headings';
import { DeriveHeaderClasses } from '~/components/Navigation';
import Hero from '~/components/.base/hero';
import { HeadingLarge, Heading, BodyText, BodyTextAlt } from '~/components/.base/headings';
import { FormControl, FormControlSelect, FormSuccess, FormError } from '~/components/.base/inputs';
import { RedButton } from '~/components/.base/buttons';
import { Grid, Row, Col, FlexBox, BackgroundDiv } from '~/components/.base/containers';
import './catering.scss';

// icons
import ArrowDown from '~/images/icons/arrow_normal_down.svg';
import { WoodenBorder } from '../components/.base/border';

import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';

const STATIC_LOCATIONS_MAP = {
  CORPORATE: {
    name: 'Corporate',
    email: 'jlyons@cousinsmainelobster.com',
  },
};

const initialErrors = {
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  city: null,
  state: null,
  zip: null,
  budget: null,
  cateringType: null,
  reqTime: null,
  reqDate: null,
  location: null,
  message: null,
};
const Catering = ({ data, location }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${size.lg - 1}px)` });
  const { allContentfulFoodTruckPage, images } = data;
  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState('');
  const [errors, setErrors] = useState(initialErrors);
  const [isReCaptchaValid, setIsReCaptchaValid] = useState(false);
  const [captchaToken, setCaptchaToken] = useState();

  const messageFieldRef = useRef(null);
  const locationFieldRef = useRef(null);
  const reqTimeFieldRef = useRef(null);
  const reqDateFieldRef = useRef(null);
  const cateringTypeFieldRef = useRef(null);
  const budgetFieldRef = useRef(null);
  const zipFieldRef = useRef(null);
  const stateFieldRef = useRef(null);
  const cityFieldRef = useRef(null);
  const phoneFieldRef = useRef(null);
  const emailFieldRef = useRef(null);
  const lastNameFieldRef = useRef(null);
  const firstNameFieldRef = useRef(null);

  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    city: '',
    state: '',
    zip: '',
    budget: '',
    cateringType: 'In Home',
    reqTime: null,
    // get next days' date
    reqDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    location: '',
    message: '',
  });
  const [minCateringDate] = useState(null);
  const anchorRef = useRef();
  const handleDateChange = async (d, setFieldValue) => {
    setFieldValue('reqDate', d);
  };
  const submitForm = async (values, { setSubmitting, resetForm }) => {
    setFormError('');
    setFormSuccess(false);
    setSubmitting(true);

    let locationEmail = null;
    const staticLocations = Object.values(STATIC_LOCATIONS_MAP);
    const staticLocation = staticLocations.find(loc => loc.name === values.location);

    if (!!staticLocation) {
      locationEmail = staticLocation.email;
      values.storeLocation = staticLocation.name;
    } else {
      locationEmail = data.allContentfulFoodTruckPage.edges.find(
        edge => edge.node.title === values.location
      )?.node?.emailAddress;
      values.storeLocation = values.location;
    }

    values.location = locationEmail;

    const formData = appendFormData(values);
    formData.delete('reqDate');
    formData.delete('reqTime');
    const dateStr = values.reqDate.toISOString().slice(0, 10);
    const timeStr = values.reqTime;
    formData.append('Requested Date', dateStr);
    formData.append('Requested Time', timeStr);
    let verifyCaptchaTokenRes = null;

    try {
      verifyCaptchaTokenRes = await axios.post(`${process.env.GATSBY_SERVER_API_URL}/api/forms/verify-captcha`, {
        token: captchaToken
      });
    } catch (e) {
      setSubmitting(false);
      setFormError(e?.message);
    }

    if (!verifyCaptchaTokenRes?.data?.success) {
      setSubmitting(false);
      setFormError('Please check the recaptcha and try again');

      return;
    }

    axios
      .post(`${process.env.GATSBY_SERVER_API_URL}/api/forms/catering`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
        },
      })
      .then(res => {
        if (window && window.ga) {
          window.ga('send', 'event', 'form', 'submission', 'Catering Form Submission', '1');
        }
        setSubmitting(false);
        setInitialValues(vals => Object.assign(vals));
        setFormSuccess(true);
      })
      .then(() => {
        resetForm({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          city: '',
          state: '',
          zip: '',
          budget: '',
          cateringType: 'In Home',
          reqTime: new Date('2018-01-01T00:00:00.000Z'),
          reqDate: new Date(),
          location: '',
          message: '',
        });
        setErrors(initialErrors);
      })
      .catch(err => {
        console.log(err);
        setSubmitting(false);
        setFormError(err.message);
      });
  };
  let uniqueTrucks = [
    ...new Set(data.allContentfulFoodTruckPage.edges.map(edge => edge.node.title).sort()),
  ];
  uniqueTrucks = uniqueTrucks.map(truck => ({
    truck,
    email: data.allContentfulFoodTruckPage.edges.find(edge => edge.node.title === truck).node
      .emailAddress,
  }));

  const handleDateError = (reason, value, errs) => {
    console.log('handleDateError');
    switch (reason) {
      case 'invalidDate':
        setErrors({
          ...errs,
          reqDate: 'Invalid date format',
        });
        break;

      case 'disablePast':
        setErrors({
          ...errs,
          reqDate: 'Values in the past are not allowed',
        });
        break;

      case 'maxDate':
        setErrors({
          ...errs,
          reqDate: `Date should not be after ${format(maxDate, 'P')}`,
        });
        break;

      case 'minDate':
        setErrors({
          ...errs,
          reqDate: `Date should not be before ${format(minDate, 'P')}`,
        });
        break;

      case 'shouldDisableDate':
        // shouldDisableDate returned true, render custom message according to the `shouldDisableDate` logic
        setErrors({
          ...errs,
          reqDate: `Date is unavailable`,
        });
        break;

      default:
        setErrors({
          ...errs,
          reqDate: undefined,
        });
    }
  };

  const formatDate = value => {
    const dateText = value.toISOString();
    return `${dateText.slice(5, 7)}/${dateText.slice(8, 10)}/${dateText.slice(0, 4)}`;
  };

  const handleReCaptchaChange = value => {
    setIsReCaptchaValid(!!value);
    setCaptchaToken(value);
  };

  useEffect(() => {
    $(document).ready(function () {
      let dateNow = Date.now();
      let today = new Date();
      let thisYear = today.getFullYear();
      let thisMonth = today.getMonth();

      function thanksgiving(year) {
        let lastOfNov = new Date(year, 10, 30).getDay();
        let thanksgiving = (lastOfNov >= 4 ? 34 : 27) - lastOfNov;
        return thanksgiving;
      }

      function isHoliday(date) {
        return (
          (date.getMonth() === 0 && date.getDate() === 1) ||
          (date.getMonth() === 6 && date.getDate() === 4) ||
          (date.getMonth() === 10 && date.getDate() === thanksgiving(date.getFullYear())) ||
          (date.getMonth() === 11 && date.getDate() === 24) ||
          (date.getMonth() === 11 && date.getDate() === 25) ||
          (date.getMonth() === 11 && date.getDate() === 31)
        );
      }

      function dateDisableFunc(date) {
        return isHoliday(new Date(date)) || dateNow > new Date(date).getTime();
      }

      function monthDisableFunc(year, month) {
        return thisYear > year || (thisYear === year && thisMonth + 1 > month);
      }

      function yearDisableFunc(year) {
        return thisYear > year;
      }

      $('.date').datepicker({
        inputFormat: 'MM/dd/yyyy',
        outputFormat: 'MM/dd/yyyy',
        isDateDisabled: dateDisableFunc,
        isMonthDisabled: monthDisableFunc,
        isYearDisabled: yearDisableFunc,
        gainFocusOnConstruction: false,
      });
    });

    console.log('calendar plugin initialized');
  }, []);

  console.log(errors);
  return (
    <>
      <MailingListPopup />
      <SEO title="Catering" />
      <Criteo />
      <DeriveHeaderClasses location={location} />
      <div className="catering-page">
        <LargeStencil
          css={css`
            transform: rotate(90deg);
            top: 6%;
            left: 285px;
            z-index: 4;
            transform-origin: 0 0;
            @media ${breakpoint.maxMd} {
              display: none;
            }
          `}
        >
          Catering
        </LargeStencil>
        <Hero
          size="full"
          imageFluid={
            isMobile
              ? getImage(images.edges, 'catering_hero_mobile')
              : getImage(images.edges, 'catering_hero')
          }
          alt=""
        >
          <div className="hero-details">
            <HeadingLarge>Catering</HeadingLarge>
            <BodyText>
              Weddings, work functions, or just throwing a great party, catering with Cousins Maine
              Lobster will always be a memorable and exceptional experience for you and your guests.
              Speak to a local Cousins Maine Lobster team member today for flexible catering options
              available in your area.
            </BodyText>
          </div>
        </Hero>
        <div className="catering-pg-container">
          <BackgroundDiv
            top="200px"
            z="-1"
            w="100%"
            h="45%"
            css={css`
              .gatsby-image-wrapper {
                height: 100%;
                width: 100%;
                img {
                  height: 100%;
                  object-fit: fill !important;
                }
              }
            `}
          >
            <WoodenBorder top="0" zIndex="1" />
            <WoodenBorder top="100%" zIndex="1" />
            <Image fluid={getImage(images.edges, 'full_blue_bg')} alt="" />
          </BackgroundDiv>
          <div className="heading-cont">
            <Heading>
              <span className="indent">Cater with </span>
              <span className="red">the Cousins</span>
            </Heading>
          </div>
          <div className="img-cont">
            <Mobile>
              <Image fluid={getImage(images.edges, 'catering_mobile')} alt="" />
            </Mobile>
            <Default>
              <Image fluid={getImage(images.edges, 'catering')} alt="" />
            </Default>
          </div>
          <div className="catering-form-cont">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validateOnChange={false}
              validateOnBlur={false}
              validate={values => {
                console.log('validate');
                const errs = {};
                let hasErrors = false;

                console.log('values', values);

                if (!values.message) {
                  hasErrors = true;
                  errs.message = 'Required';
                  if (messageFieldRef?.current) messageFieldRef.current.focus();
                }
                if (!values.location) {
                  hasErrors = true;
                  errs.location = 'Required';
                  if (locationFieldRef?.current) locationFieldRef.current.focus();
                }
                if (!values.reqTime || values.reqTime === null) {
                  hasErrors = true;
                  errs.reqTime = 'Required';
                  if (reqTimeFieldRef?.current) reqTimeFieldRef.current.focus();
                }
                if (!values.reqDate || values.reqDate === null) {
                  hasErrors = true;
                  errs.reqDate = 'Required';
                  console.log(reqDateFieldRef);
                  if (reqDateFieldRef?.current) reqDateFieldRef.focus();
                }
                if (!values.cateringType) {
                  hasErrors = true;
                  errs.cateringType = 'Required';
                  if (cateringTypeFieldRef?.current) cateringTypeFieldRef.current.focus();
                }
                if (!values.budget) {
                  hasErrors = true;
                  errs.budget = 'Required';
                  if (budgetFieldRef?.current) budgetFieldRef.current.focus();
                }
                if (!values.zip) {
                  hasErrors = true;
                  errs.zip = 'Required';
                  if (zipFieldRef?.current) zipFieldRef.current.focus();
                }
                if (!values.state) {
                  hasErrors = true;
                  errs.state = 'Required';
                  if (stateFieldRef?.current) stateFieldRef.current.focus();
                }
                if (!values.city) {
                  hasErrors = true;
                  errs.city = 'Required';
                  if (cityFieldRef?.current) cityFieldRef.current.focus();
                }
                if (!values.phone) {
                  hasErrors = true;
                  errs.phone = 'Required';
                  if (phoneFieldRef?.current) phoneFieldRef.current.focus();
                }
                if (values.email === '') {
                  hasErrors = true;
                  errs.email = 'Required';
                  if (emailFieldRef?.current) emailFieldRef.current.focus();
                } else if (
                  values.email &&
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  hasErrors = true;
                  errs.email = 'Invalid email address';
                  if (emailFieldRef?.current) emailFieldRef.current.focus();
                }
                if (!values.lastName) {
                  hasErrors = true;
                  errs.lastName = 'Required';
                  if (lastNameFieldRef?.current) lastNameFieldRef.current.focus();
                }
                if (!values.firstName) {
                  hasErrors = true;
                  errs.firstName = 'Required';
                  if (firstNameFieldRef?.current) firstNameFieldRef.current.focus();
                }
                setErrors(hasErrors ? errs : null);
                return errs;
              }}
              onSubmit={submitForm}
            >
              {({
                values,
                errs,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form
                  className="catering-form"
                  css={css`
                    ${FormControl} {
                      input,
                      textarea {
                        width: calc(100% - 15px);
                      }
                    }
                  `}
                >
                  <BodyTextAlt md="0 0 2rem">* indicates a required field</BodyTextAlt>
                  <Grid>
                    <Row>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="firstName">
                            First Name
                            <span className="red">*</span>
                          </label>
                          <input
                            id="firstName"
                            name="firstName"
                            className={`field${!!errors?.firstName ? ' is-invalid' : ''}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                            aria-describedby="firstNameError"
                            ref={firstNameFieldRef}
                            autoComplete="given-name"
                          />
                          <div id="firstNameError" className="error">
                            {errors?.firstName}
                          </div>
                        </FormControl>
                      </Col>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="lastName">
                            Last Name
                            <span className="red">*</span>
                          </label>
                          <input
                            id="lastName"
                            name="lastName"
                            className={`field${!!errors?.lastName ? ' is-invalid' : ''}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                            aria-describedby="lastNameError"
                            ref={lastNameFieldRef}
                            autoComplete="family-name"
                          />
                          <div id="lastNameError" className="error">
                            {errors?.lastName}
                          </div>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="email">
                            Email
                            <span className="red">*</span>
                          </label>
                          <input
                            id="email"
                            name="email"
                            className={`field${!!errors?.email ? ' is-invalid' : ''}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            aria-describedby="emailError"
                            ref={emailFieldRef}
                            autoComplete="email"
                          />
                          <div id="emailError" className="error">
                            {errors?.email}
                          </div>
                        </FormControl>
                      </Col>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="phone">
                            Phone
                            <span className="red">*</span>
                          </label>
                          <input
                            id="phone"
                            name="phone"
                            className={`field${!!errors?.phone ? ' is-invalid' : ''}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                            aria-describedby="phone"
                            ref={phoneFieldRef}
                            autoComplete="tel"
                          />
                          <div id="phoneError" className="error">
                            {errors?.phone}
                          </div>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="city">
                            City
                            <span className="red">*</span>
                          </label>
                          <input
                            id="city"
                            name="city"
                            className={`field${!!errors?.city ? ' is-invalid' : ''}`}
                            placeholder="Enter your city here"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.city}
                            aria-describedby="cityError"
                            ref={cityFieldRef}
                            autoComplete="address-level3"
                          />
                          <div id="cityError" className="error">
                            {errors?.city}
                          </div>
                        </FormControl>
                      </Col>
                      <Col size={1}>
                        <FormControlSelect svgTop="45px" svgRight="35px" w="calc(100% - 15px)">
                          <label htmlFor="state">
                            State
                            <span className="red">*</span>
                          </label>
                          <select
                            id="state"
                            name="state"
                            className={`field${!!errors?.state ? ' is-invalid' : ''}`}
                            placeholder="Country"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.state}
                            aria-describedby="stateError"
                            ref={stateFieldRef}
                            autoComplete="address-level1"
                          >
                            <option value="">Select State</option>
                            {Object.entries(stateLists['US']).map((state, i) => (
                              <option key={i} value={state[0]}>
                                {state[1]}
                              </option>
                            ))}
                          </select>
                          <ArrowDown
                            style={{
                              pointerEvents: 'none',
                            }}
                          />
                          <div id="stateError" className="error">
                            {errors?.state}
                          </div>
                        </FormControlSelect>
                      </Col>

                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="zip">
                            Zip
                            <span className="red">*</span>
                          </label>
                          <input
                            id="zip"
                            name="zip"
                            className={`field${!!errors?.zip ? ' is-invalid' : ''}`}
                            placeholder="eg. 90210"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.zip}
                            aria-describedby="zipError"
                            ref={zipFieldRef}
                            autoComplete="postal-code "
                          />
                          <div id="zipError" className="error">
                            {errors?.zip}
                          </div>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="budget">
                            Budget ($)
                            <span className="red">*</span>
                          </label>
                          <input
                            id="budget"
                            name="budget"
                            className={`field${!!errors?.budget ? ' is-invalid' : ''}`}
                            placeholder="Enter your budget here"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.budget}
                            aria-describedby="budgetError"
                            ref={budgetFieldRef}
                          />
                          <div id="budgetError" className="error">
                            {errors?.budget}
                          </div>
                        </FormControl>
                      </Col>
                      <Col size={1}>
                        <FormControlSelect svgTop="45px" svgRight="35px" w="calc(100% - 15px)">
                          <label htmlFor="cateringType">
                            Catering Type
                            <span className="red">*</span>
                          </label>
                          <select
                            id="cateringType"
                            name="cateringType"
                            className={`field${!!errors?.cateringType ? ' is-invalid' : ''}`}
                            placeholder="Country"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cateringType}
                            aria-describedby="cateringTypeError"
                            ref={cateringTypeFieldRef}
                          >
                            <option value="In Home">In Home</option>
                            <option value="Truck">Truck</option>
                          </select>
                          <ArrowDown
                            style={{
                              pointerEvents: 'none',
                            }}
                          />
                          <div id="cateringTypeError" className="error">
                            {errors?.cateringType}
                          </div>
                        </FormControlSelect>
                      </Col>
                      <Col size={1}>
                        <FormControl
                          className={`date-picker${errors?.reqDate ? ' is-invalid' : ''}`}
                        >
                          <label htmlFor="cateringType">
                            Requested Date
                            <span className="red">*</span>
                          </label>
                          <input
                            className="date form-control"
                            id="date1"
                            type="text"
                            value={formatDate(values.reqDate)}
                            placeholder="MM/dd/yyyy"
                            onBlur={e => {
                              const dateValues = e.target.value.split('/');
                              handleDateChange(
                                dateValues.length > 2
                                  ? new Date(`${dateValues[2]}-${dateValues[0]}-${dateValues[1]}`)
                                  : new Date(),
                                setFieldValue
                              );
                            }}
                          />
                          <div className="popover-root" ref={anchorRef}></div>
                          <div id="reqDateError" className="error">
                            {errors?.reqDate}
                          </div>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col size={1}>
                        <FormControl
                          className={`date-picker${errors?.reqTime ? ' is-invalid' : ''}`}
                        >
                          <TextField
                            placeholder="08:00am - 11:00am"
                            id="reqTime"
                            name="reqTime"
                            value={values.reqTime}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            aria-describedby="reqTimeError"
                            inputRef={reqTimeFieldRef}
                            label={
                              <>
                                Requested Time
                                <span className="red">*</span>
                              </>
                            }
                          />
                          <div className="popover-root" ref={anchorRef}></div>
                          <div className="error">{errors?.reqTime}</div>
                        </FormControl>
                      </Col>
                      <Col size={1}>
                        <FormControlSelect svgTop="45px" svgRight="35px" w="calc(100% - 15px)">
                          <label htmlFor="location">
                            Location
                            <span className="red">*</span>
                          </label>
                          <select
                            id="location"
                            name="location"
                            placeholder="Country"
                            className={`field${!!errors?.location ? ' is-invalid' : ''}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.location}
                            aria-describedby="locationError"
                            ref={locationFieldRef}
                            autoComplete="address-level2"
                          >
                            <option value="">Select Location</option>
                            <option value={STATIC_LOCATIONS_MAP.CORPORATE.name}>Corporate</option>
                            {uniqueTrucks.map(truck => (
                              <option value={truck.truck}>{truck.truck}</option>
                            ))}
                          </select>
                          <ArrowDown
                            style={{
                              pointerEvents: 'none',
                            }}
                          />
                          <div id="locationError" className="error">
                            {errors?.location}
                          </div>
                        </FormControlSelect>
                      </Col>
                    </Row>
                    <Row>
                      <Col size={1}>
                        <FormControl>
                          <label htmlFor="message">
                            Questions or Comments
                            <span className="red">*</span>
                          </label>
                          <textarea
                            id="message"
                            name="message"
                            className={`field${!!errors?.message ? ' is-invalid' : ''}`}
                            rows="5"
                            placeholder="Enter your message here, we're all ears!"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.message}
                            aria-describedby="messageError"
                            ref={messageFieldRef}
                          />
                          <div id="messageError" className="error">
                            {errors?.message}
                          </div>
                        </FormControl>
                      </Col>
                    </Row>
                    {formSuccess ? (
                      <FormSuccess role="alert">
                        <BodyTextAlt>
                          Thank you for choosing Cousins Maine Lobster for your event! We will get
                          back to you shortly.
                        </BodyTextAlt>
                      </FormSuccess>
                    ) : null}
                    {formError.length ? (
                      <FormError>
                        <BodyTextAlt>{formError}</BodyTextAlt>
                      </FormError>
                    ) : null}
                    <FlexBox justify="center">
                      <ReCAPTCHA
                        className="recaptcha"
                        sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
                        onChange={handleReCaptchaChange}
                      />
                    </FlexBox>
                    <FlexBox justify="center">
                      <RedButton
                        type="submit"
                        onClick={handleSubmit}
                        disabled={!isReCaptchaValid || (isReCaptchaValid && isSubmitting)}
                        h="50px"
                        pd="0 35px"
                      >
                        {isSubmitting ? (
                          <>
                            <Loader type="TailSpin" color="#152622" height={16} width={16} />
                            Loading...
                          </>
                        ) : (
                          'Submit'
                        )}
                      </RedButton>
                    </FlexBox>
                  </Grid>
                </form>
              )}
            </Formik>
          </div>
        </div>
        <BackgroundDiv
          css={css`
            width: 56.67vw;
            left: 0;
            bottom: -200px;
            z-index: -1;
            transform: translateX(-27.47%);
            @media ${breakpoint.maxMd} {
              display: none;
            }
          `}
        >
          <Image fluid={getImage(images.edges, 'blurred_smudge_3@3x')} alt="" />
        </BackgroundDiv>
        <Image
          className="girl-feeding-guy"
          fluid={getImage(images.edges, 'girlfeeding_guy')}
          alt=""
        />
      </div>
    </>
  );
};

export const query = graphql`
  {
    allContentfulFoodTruckPage {
      edges {
        node {
          title
          handle
          emailAddress
        }
      }
    }
    images: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: {
          regex: "/images/background/|/images/catering/|/images/homepage/|/images/locations/"
        }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1600, quality: 80) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default Catering;
